import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/solar-logo.svg";

import { AuthContext } from "../auth/auth";
import sidebarclose from "../assets/sidebar-close.svg";
import sidebaropen from "../assets/sidebar-open.svg";

import { MdPlaylistAdd } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { BiSolidLogOut } from "react-icons/bi";

const Sidebar = ({ selectedProject }) => {
  console.log("🚀 ~ Sidebar ~ selectedProject:", selectedProject);
  const [arrow, setArrow] = useState(true);
  const navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setAuthState({});
    navigate("/");
  };

  return (
    <aside className={arrow ? "aside-navsidebar" : "aside-close"}>
      <div className="arrow">
        {arrow ? (
          <img
            src={sidebaropen}
            alt={sidebaropen}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        ) : (
          <img
            src={sidebarclose}
            alt={sidebarclose}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        )}
      </div>
      <div style={{ cursor: "pointer" }} className="nav-logo">
        {" "}
        <img
          onClick={() => navigate("/homepage")}
          src={logo}
          className="sidebar-logo"
          alt="logo-S.I"
        />
        <h1 className={arrow ? "" : "display-none"}>
          Synergy<span>Buddy</span>
        </h1>
      </div>

      <nav className="nav-sidebar">
        <NavLink className="hover-link" to={`/campagnes/${selectedProject}`}>
          <AiFillHome size={25} className="img" />
          <span className={arrow ? "nav-links" : "display-none"}>Home</span>
        </NavLink>
        {/*         <NavLink className={`hover-link`} to={`/campagnes/${selectedProject}`}>
          <HiClipboardDocumentList size={25} className="img" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Campagnes
          </span>
        </NavLink> */}
        <NavLink className="hover-link" to={`/campagne/${selectedProject}`}>
          <MdPlaylistAdd size={25} className="img" />
          <span className={arrow ? "nav-links" : "display-none"}>Campagne</span>
        </NavLink>

        <NavLink className={`hover-link`} to={`/parametres/${selectedProject}`}>
          <IoMdSettings size={25} className="img" />
          <span className={arrow ? "nav-links" : "display-none"}>
            Paramètres
          </span>
        </NavLink>
      </nav>

      <footer>
        <div className="logout" onClick={handleLogout}>
          <BiSolidLogOut size={25} className="img" />
          <span
            style={{ marginLeft: "0.7rem" }}
            className={arrow ? "" : "display-none"}
          >
            Logout
          </span>
        </div>
      </footer>
    </aside>
  );
};

export default Sidebar;
