import React, { useContext, useEffect, useState } from "react";
import {
  Title,
  Input,
  Select,
  Switch,
  Radio,
  CheckIcon,
  Container,
  Box,
  TextInput,
  Group,
  Button,
  Checkbox,
  NumberInput,
  Flex,
  SimpleGrid,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import "@mantine/dates/styles.css";
import { toast } from "react-toastify";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import * as moment from "moment";
import "dayjs/locale/fr";
import useProjectContext from "../hooks/useProjectContext";
import { country, domain, langues } from "../utils/utils";

const Campaign = () => {
  console.log("Campaign component loaded");
  const [allProjects, setAllProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const { projectId } = useProjectContext();

  const { id } = useParams();
  console.log("🚀 ~ Campaign ~ id:", id);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      /*       clientId: "", */
      /*  campaignName: "", */
      domain: "",
      keyword: "",
      startDate: new Date(),
      endDate: new Date(),
      device: "",
      ecommerce: "",
      idGoogleAds: "",
      idCampaign: "",
      observationScenario: "",
      location: "",
      triggerFrequency: 0,
      google_domain: "",
      google_language: "",
      language: "",
    },
    validate: {
      /*       campaignName: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      }, */
      /*       clientId: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      }, */
      keyword: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      domain: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      device: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      // location: (value) => {
      //   return value.length === 0 ? "Veuillez remplir ce champs" : null;
      // },
      triggerFrequency: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      startDate: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      endDate: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      google_domain: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      google_language: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      language: (value) => {
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      /*       siteUrl: (value) => (value !== null ? null : "Breed can't be empty"),
      apiKey: (value) => (value !== null ? null : "Name can't be empty"), */
    },
  });

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/client/all/${authState.id}`);
        setAllProjects(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchProjects();
  }, [authState.id]);

  const handleSubmitCampaignData = async (data) => {
    console.log(
      "🚀 ~ file: Campaign.js:80 ~ handleSubmitCampaignData ~ data:",
      data
    );

    const startDate = moment(data.startDate).format("YYYY-MM-DD");
    const endDate = moment(data.endDate).format("YYYY-MM-DD");
    if (form.isValid) {
      //Submit form
      try {
        const res = await instance.post(`/campaign/create`, {
          /*  name: data.campaignName, */
          client_id: id,
          keyword_to_track: data.keyword,
          start_date: startDate,
          end_date: endDate,
          device: data.device,
          e_commerce: data?.ecommerce.length === 0 ? false : data?.ecommerce,
          observation_scenario:
            data?.observationScenario.length === 0
              ? false
              : data?.observationScenario,
          location: data?.location,
          domain: data.domain,
          google_domain: data.google_domain,
          google_language: data.google_language,
          language: data.language,
          trigger_frequency: data.triggerFrequency,
        });
        console.log(
          "🚀 ~ file: Campaign.js:110 ~ handleSubmitCampaignData ~ res:",
          res
        );

        toast.success("Votre campagne a bien été crée.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        form.reset();
        navigate(`/campagnes/${id}`);
        // console.log("🚀 ~ file: Navbar.js:93 ~ handleSubmitClient ~ res:", res);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("Error:", error);
      }
    }
    //Or the other way
  };

  return (
    <section
      className="campaign-section"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="campaign-div">
        <Title style={{ color: "#E2725B" }} order={2}>
          Création campagne
        </Title>
      </div>

      <form
        style={{ width: "80%", marginTop: "2rem" }}
        onSubmit={form.onSubmit((values) => handleSubmitCampaignData(values))}
      >
        <SimpleGrid cols={3}>
          {/* <Select
            size="ms"
            placeholder="Sélectionnez un client"
            label="Client"
            searchable
            data={allProjects.map((project) => ({
              value: project.id,
              label: project.name,
            }))}
            // value={selectedProject}
            withAsterisk
            //   onChange={(value) => {
            //     handleProjectChange(value);
            //   }}
            {...form.getInputProps("clientId")}
          /> */}
          {/*           <TextInput
            withAsterisk
            label="Nom de la campagne"
            placeholder="La Centrale - Observation"
            {...form.getInputProps("campaignName")}
          /> */}
          {/*             <Input.Wrapper label="Nom de la campagne">
              <Input placeholder="La Centrale - Observation" />
            </Input.Wrapper> */}
          <TextInput
            withAsterisk
            label="Mot clé à surveiller"
            placeholder="Ma voiture cash"
            {...form.getInputProps("keyword")}
          />
          {/*             <Input.Wrapper label="Mot clé à surveiller">
              <Input placeholder="Ma voiture cash" />
            </Input.Wrapper> */}
          <TextInput
            withAsterisk
            label="Domaine"
            placeholder="lacentrale.fr"
            {...form.getInputProps("domain")}
          />
          <Select
            label="Emplacement"
            searchable
            placeholder="Choissisez une valeur"
            data={[
              "Paris Paris Ile-de-France France",
              "Levallois-Perret Ile-de-France France",
            ]}
            {...form.getInputProps("location")}
          />
          <Select
            label="Google Domaine"
            placeholder="Choissisez une valeur"
            searchable
            data={domain}
            {...form.getInputProps("google_domain")}
            withAsterisk
          />
          <Select
            label="Google Country"
            placeholder="Choissisez une valeur"
            searchable
            data={country}
            {...form.getInputProps("google_language")}
            withAsterisk
          />
          <Select
            label="Langues"
            placeholder="Choissisez une valeur"
            searchable
            data={langues}
            {...form.getInputProps("language")}
            withAsterisk
          />
          {/*             <Input.Wrapper label="Domaine">
              <Input placeholder="lacentrale.fr" />
            </Input.Wrapper> */}
          <NumberInput
            label="Fréquence de déclenchement en (min)"
            withAsterisk
            placeholder="2min..."
            {...form.getInputProps("triggerFrequency")}
          />
          <DateInput
            label="Date de début"
            placeholder="Date de début"
            minDate={new Date()}
            {...form.getInputProps("startDate")}
            locale="fr"
            clearable
            withAsterisk
          />
          <DateInput
            label="Date de fin"
            placeholder="Date de fin"
            minDate={new Date()}
            {...form.getInputProps("endDate")}
            locale="fr"
            withAsterisk
            clearable
          />
          <Select
            label="Device"
            placeholder="Choissisez une valeur"
            data={["desktop"]}
            {...form.getInputProps("device")}
            withAsterisk
          />
        </SimpleGrid>
        {/*         <Switch
          style={{ marginTop: "2rem" }}
          label="Campagne e-commerce"
          {...form.getInputProps("ecommerce")}
          size="md"
        /> */}
        <div
          style={{
            marginTop: "2rem",
            width: "100%",
          }}
        >
          {/*           <div className="campaign-div">
            <Title style={{ color: "#E2725B" }} order={3}>
              Infos Google Ads (facultatif)
            </Title>
          </div> */}

          <SimpleGrid style={{ marginTop: "2rem" }} cols={3}>
            {/*             <TextInput
              label="Identifiant Compte Google Ads"
              placeholder="L'dentifiant compte google ads"
              {...form.getInputProps("idGoogleAds")}
            /> */}
            {/*             <Input.Wrapper label="Identifiant Compte Google Ads">
              <Input placeholder="L'dentifiant compte google ads" />
            </Input.Wrapper> */}
            {/*             <TextInput
              label="Id de la campagne"
              placeholder="Id de la campagne"
              {...form.getInputProps("idCampaign")}
            /> */}
          </SimpleGrid>
          {/*             <Input.Wrapper label="Id de la campagne">
              <Input placeholder="La Centrale - Observation" />
            </Input.Wrapper> */}
        </div>
        {/*         <div style={{ marginTop: "2rem" }}>
          <div className="campaign-section">
            <Title style={{ color: "#E2725B" }} order={4}>
              Scénario de la campagne
            </Title>
          </div>
          <Checkbox
            style={{ marginTop: "1rem" }}
            label="Scénario Observation"
            description="Avoir des statistiques sur le mot clé a surveiller"
            color="orange"
            {...form.getInputProps("observationScenario")}
          />
        </div> */}
        <Group justify="center" mt="md">
          <Button
            color="#E2725B"
            size="md"
            style={{ marginTop: ".3rem" }}
            type="submit"
          >
            Envoyer
          </Button>
        </Group>
      </form>
    </section>
  );
};

export default Campaign;
