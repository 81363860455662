import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/auth";
import instance from "../axios/global";
import user from "../assets/user.svg";
import {
  Select,
  Modal,
  Button,
  Input,
  TextInput,
  Checkbox,
  Group,
  Box,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import line from "../assets/line.svg";
import calendar from "../assets/calendar.svg";
import { useForm } from "@mantine/form";
import { toast } from "react-toastify";
import useProjectContext from "../hooks/useProjectContext";

const Navbar = ({
  selectedProject,
  onProjectChange,
  allProjects,
  updateProjectsList,
}) => {
  console.log("🚀 ~ selectedProject:", selectedProject);

  const navigate = useNavigate();
  const currentDate = new Date();
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [projectInfos, setProjectInfos] = useState(""); // Vous pouvez laisser cette ligne si elle est utilisée ailleurs dans le composant
  const [opened, { open, close }] = useDisclosure(false);
  const { projectId, setProjectId, setProjectName, setProjectIdReport } =
    useProjectContext();

  const form = useForm({
    initialValues: {
      name: "",
      siteUrl: "",
      apiKey: "",
    },
    validate: {
      name: (value) => {
        console.log("🚀 ~ file: Navbar.js:38 ~ Navbar ~ value:", value);
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      /*       siteUrl: (value) => (value !== null ? null : "Breed can't be empty"),
      apiKey: (value) => (value !== null ? null : "Name can't be empty"), */
    },
  });

  const handleProjectChange = (event) => {
    setProjectId(event);
    const newProject = event?.target?.value;
    // onProjectChange(newProject);
  };
  const fetchProjectInfos = async () => {
    if (!selectedProject) {
      return;
    }

    try {
      const res = await instance.post(`client/${selectedProject}`, {
        userId: authState?.id,
      });
      setProjectInfos(res.data);
      setProjectName(res?.data?.name);
      setProjectIdReport(res?.data?.id);

      // Vérifiez l'URL actuelle pour éviter une redirection si vous êtes sur la page de création
      if (window.location.pathname !== `/campagne/${selectedProject}`) {
        navigate(`/campagnes/${selectedProject}`);
      }
    } catch (error) {
      console.log(
        "Erreur lors de la récupération des informations du projet :",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedProject) {
      fetchProjectInfos();
    }
  }, [selectedProject]);

  // Options de formatage pour obtenir le numéro du jour et le mois abrégé
  const dateFormatOptions = { day: "numeric", month: "short" };

  const formattedDate = currentDate.toLocaleString("fr-FR", dateFormatOptions);

  const handleSubmitClient = async (data) => {
    console.log("🚀 ~ file: Navbar.js:83 ~ handleSubmitClient ~ data:", data);

    if (form.isValid) {
      //Submit form
      try {
        const res = await instance.post(`/client/create/${authState.id}`, {
          name: data.name,
          site_url: data.siteUrl,
          api_key: data.apiKey,
        });
        /*         fetchProjects(); */
        toast.success("Votre client a bien été crée.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        form.reset();
        close();
        navigate(`/campagnes/${selectedProject}`);
        updateProjectsList();
        console.log("🚀 ~ file: Navbar.js:93 ~ handleSubmitClient ~ res:", res);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("Error:", error);
      }
    }
    //Or the other way
  };

  return (
    <div className="navbar">
      <div className="user-container">
        <img className="img-user" src={user} alt="user" />
        <div className="user-infos">
          <h2> {authState.name}</h2>
          <span>{authState.email}</span>
        </div>
        {/*         <div className="date">
          <img src={line} alt="line" />
          <p>{formattedDate}</p>
          <div className="container-calendar">
            <img src={calendar} alt="date" />
          </div>
        </div> */}
      </div>
      <div className="container-projects">
        {/* <div>
          <button
            className="btn-new-project"
            onClick={() => navigate("/campagne")}
          >
            + Nouvelle campagne
          </button>
        </div> */}
        <div>
          <button className="btn-new-project" onClick={open}>
            + Nouveau client
          </button>
        </div>

        <div className="my-projects">
          <Select
            placeholder="Sélectionnez un client"
            searchable
            data={allProjects.map((project) => ({
              value: project.id,
              label: project.name,
            }))}
            value={selectedProject}
            onChange={(value) => onProjectChange(value)}
            checkIconPosition="right"
          />
        </div>
      </div>
      <Modal opened={opened} onClose={close} title="Créer un client" size="lg">
        <Box maw={340} mx="auto">
          <form
            onSubmit={form.onSubmit((values) => handleSubmitClient(values))}
          >
            <TextInput
              style={{ marginTop: "2rem" }}
              withAsterisk
              label="Nom"
              placeholder="Audi...."
              {...form.getInputProps("name")}
            />
            <TextInput
              style={{ marginTop: "2rem" }}
              label="Site url"
              placeholder="Site url...."
              {...form.getInputProps("siteUrl")}
            />
            {/*             <TextInput
              style={{ marginTop: "2rem" }}
              label="Clé api"
              placeholder="Clé api...."
              {...form.getInputProps("apiKey")}
            /> */}
            <Group justify="flex-end" mt="md">
              <Button
                color="#E2725B"
                style={{ marginTop: "1rem" }}
                type="submit"
              >
                Submit
              </Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Navbar;
