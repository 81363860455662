import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import Settings from "./Settings";
import Homepage from "./Homepage";
import Campaign from "./Campaign";
import ClientCampaign from "./ClientCampaign";
import Report from "./Report";
import ProjectContextProvider from "../context/projectContext";
import { useContext } from "react";
import { AuthContext } from "../auth/auth";
import instance from "../axios/global";

const Accueil = () => {
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  console.log("🚀 ~ Accueil ~ selectedProject:", selectedProject);
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    instance
      .get("/user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          setAuthState({
            name: res.data.firstName,
            email: res.data.email,
            id: res.data.id,
            status: true,
          });
        }
      });
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await instance.get(`/client/all/${authState.id}`);
        if (res?.data.length > 0) {
          setAllProjects(res.data);

          if (!selectedProject) {
            setSelectedProject(res?.data[0]?.id.toString());
          }
        } else {
          setAllProjects([]);
        }
      } catch (error) {
        console.log("Erreur lors du chargement des projets : ", error);
      }
    };
    if (authState?.id) {
      fetchProjects();
    }
  }, [authState?.id, selectedProject, setAllProjects]);

  const updateProjectsList = async () => {
    try {
      const res = await instance.get(`/client/all/${authState?.id}`);
      setAllProjects(res?.data);
    } catch (error) {
      console.log("Erreur lors du chargement des projets : ", error);
    }
  };

  const handleProjectChange = (newProject) => {
    setSelectedProject(newProject);
  };

  return (
    <div className="accueil">
      <Sidebar selectedProject={selectedProject} />
      <div className="content">
        <Navbar
          selectedProject={selectedProject}
          onProjectChange={handleProjectChange}
          allProjects={allProjects}
          updateProjectsList={updateProjectsList}
        />
        <section className="section-switch-nav">
          <div className="div-switch">
            <Routes>
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/campagne/:id" element={<Campaign />} />
              <Route path="/campagnes/:id" element={<ClientCampaign />} />
              <Route path="/parametres/:id" element={<Settings />} />
              <Route path="/client/:id/:name" element={<ClientCampaign />} />
              <Route path="/rapport/:id" element={<Report />} />
            </Routes>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Accueil;
